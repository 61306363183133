import Link from 'next/link'
import { Button, Col, Row, Typography } from 'antd'
import Page from 'components/page/page'
import Spacer from 'components/spacer'
import styles from './404.module.less'

const { Text } = Typography

const VierNullVier = () => (
	<Page title='404' footer='slim'>
		<div className={styles.container}>
			<img src='/images/404.jpg' className={styles.image} />
			<Row justify='end' align='middle' className={styles.row}>
				<Col className={styles.content} span={24} md={16} lg={12}>
					<div>
						<Text className={styles.title}>404</Text>
						<Spacer size={12} />
					</div>
					<div>
						<Text className='semibold-30 grey-1'>Der geht auf uns!</Text>
						<Spacer size={12} />
						<Text className='regular-24 grey-1'>
							Diese Seite scheint nicht (mehr) zu existieren oder der Link funktioniert nicht.
						</Text>
						<Spacer size={32} />
						<Link href='/'>
							<Button type='primary' size='large'>
								zur Startseite
							</Button>
						</Link>
						<Spacer size={128} />
					</div>
				</Col>
			</Row>
		</div>
	</Page>
)

export default VierNullVier
